<template lang="pug">
	section#parent
		h2.title {{ contents.title }}
		h3.subTitle {{ contents.subtitle }}
		.wrapper
			ul.subOptions
				li(v-for="item, index in sortedChildren" :class="{ ativo: active == index, displayArrow: active == index && +nestingLevel !== 0 }").buttons
					a(:href="item.content", v-if="item.type == 'external' || item.type == 'internal'", :target="item.type == 'external' ? '_blank' : ''" v-html="item.title")
					span(v-else v-html="item.title" @click="toggle(index)")
					p(v-if="item.tooltip" v-html="item.tooltip").modal
			hr(v-if="active != null && +nestingLevel === 0")
			Content(:contents="parent", v-if="active != null" :path="getNewPath")
</template>

<script>
import { props, faleConoscoComponents } from '@/mixins/component'

export default {
	name: "section-parent",
	props: {
		contents: Object,
		nestingLevel: String,
		path: Array,
	},
	components: {
		...faleConoscoComponents,
	},
	data() {
		return {
			active: null,
		}
	},
	computed: {
		parent() {
			if (this.active == null)
				return {}
			return this.contents.children[this.active]
		},
		getPath() {
			return this.path
		},
		getNewPath() {
			return this.path.filter(item => item !== this.contents.children[this.active].id)
		},
		getContents() {
			return this.contents
		},
		sortedChildren() {
			return this.contents.children.sort((a,b) => {
				if ( b.order > a.order) return -1
				if ( b.order < a.order ) return 1
				return 0
			})
		}
	},
	watch: {
		getContents: {
			immediate: true,
			handler: function () {
				if (this.path) {
					this.setActive()
				}
			}
		}
	},
	methods: {
		toggle(index) {
			if (index == this.active)
				this.active = null
			else
				this.active = index
		},
		setActive() {
			if (this.active != null) 
				return
			if(this.contents.id) {
				this.active = this.contents.children.indexOf(this.contents.children.find(item => item.id === this.getPath[0]))
				if (this.active < 0) this.active = null; 
			}
		},
	},
}
</script>

<style lang="stylus" scoped src="./Parent.styl"></style>
